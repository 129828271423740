<template>
	<div class="p-follow">
		<v-container>
			<h2 class="_title">Following</h2>
			<div class="follow_nav">
				<router-link :to="{name: 'followingAll'}">All</router-link>
				<router-link :to="{name: 'followingUsers'}">Users</router-link>
				<router-link :to="{name: 'followingArtists'}">Artists</router-link>
				<router-link :to="{name: 'followingLabels'}">Labels</router-link>

				<div class="custom_switch" @click="switchToggle" :class="{'active': FOLLOWS_FILTER}" v-if="$router.currentRoute.name !== 'customList'">
					Filter
					<div class="switch_thumb"></div>
				</div>
			</div>
			<followFilterBar></followFilterBar>
			<router-view></router-view>
		</v-container>
		<comingSoonDialog v-if="dialogComingSoon" v-model="dialogComingSoon"></comingSoonDialog>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import followFilterBar from "@/components/follow/followFilterBar";
import comingSoonDialog from '@/components/dialogs/comingSoonDialog.vue';

export default {
	name: "following",
	components: {
		followFilterBar,
		comingSoonDialog
	},
	data() {
		return {
			followFilter: this.FOLLOWS_FILTER,
			dialogComingSoon: false
		}
	},
	computed: {
		...mapGetters(['FOLLOWS_FILTER']),
	},
	mounted() {
		this.followFilter = this.FOLLOWS_FILTER;
	},
	methods: {
		switchToggle() {
			this.dialogComingSoon = true;
			// this.followFilter = !this.followFilter;
			// this.$store.commit('SET_FOLLOWS_FILTER', this.followFilter);
		}
	}
}
</script>

<style scoped>

</style>
